//$primary: #203864;
//$cyan: #2F5597;
//
//$yellow: #febb00;
//$secondary: #9dc3e6;
//$dark: #848a96;
//$white: #fff;
//
//$border: rgba($dark, 0.3);

//@import "../../node_modules/bootstrap/scss/variables";
//@import "../../node_modules/bootstrap/scss/mixins";

 @mixin media-max($width) {
     @media (max-width: $width) {
         @content;
     }
 }
 @mixin media-min($width) {
     @media (min-width: $width) {
         @content;
     }
 }
// Bootstrap Breackpoint:
//     xs = Extra small <576px
//     sm = Small ≥576px
//     md = Medium ≥768px
//     lg = Large ≥992px
//     xl = Extra large ≥1200px
$xs: 0; // Extra small screen / phone
$sm: 576px; // Small screen / phone
$md: 768px; // Medium screen / tablet
$lg: 992px; // Large screen / desktop
$xl: 1200px; // Extra large screen / wide desktop

$main-red: #DD0105;
$main-grey: #999999;
$main-black-1: #666666;
$main-black-2: #000;



body {
    font-family: 'Work Sans', sans-serif;
    &#category {
        section#wrapper {
            //margin-top: 1rem;
            @include media-min($xl) {
                //margin-top: 0;
            }
        }
    }
}
h3 {
    font-size: 22px;
    text-transform: uppercase;
}
.row:after {
    display: none;
}

.pos_title {
    margin-bottom: 16px;
    padding-bottom: 8px;
    text-align: center;
    @include media-min($md) {
        text-align: left;
    }
}
//.pt_custommenu .pt_menu .parentMenu a {
//    font-size: 12px;
//}


/**
***************************************************************
** Suppresion provisoir des boutons de mise au paniers et autres..

.add-to-links, .product-price-and-shipping, .product-add-to-cart, .product-prices

.modal-content .product-actions,
.modal-content .product-prices
***************************************************************
 */
//.add-to-links, .product-price-and-shipping, .product-add-to-cart {
//    display: none;
//}
//.modal-content .product-actions{
//    display: none;
//}


/// Modal des produits ////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
.modal-dialog {
    .modal-content {
        .modal-header {}
        .modal-body{}
        .modal-footer {
            display: flex;
            .social-sharing {
                width: 12rem;
                padding-top: 0;
            }
            #send_friend_button { margin-left: auto }
        }
    }
}

/// banner et menu ////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
header#header {
    .header-nav {
        min-height: 43px;
        background: $main-black-2;
        border-bottom: none;
        .header_logo {
            display: none;
            @include media-min($xl) {
                display: block;
            }
            @include media-min(1560px) {
                display: none;
            }
            img {
                width: 7rem;
            }
        }
        .contact-nav {
            margin-top: 14px;
            margin-left: auto;
            color: #fff;
            a { color: #fff; }
            @include media-min(1560px) {
                margin-left: auto;
            }
        }
    }
    .recherche-par-categorie {
        width: 100%;
        @include media-min(1560px) {
            display: none;
        }
        input {
            height: 100% !important;
            padding-right: 0!important;
            border-radius: inherit;
        }
    }
    .header-top {
        //padding: 0 1rem 0 0;
        @include media-min($xl) {
            padding: 1rem 4rem !important;
        }
        & > div {
            padding: 0;
            @include media-min($xl) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
        .header_logo_desktop {
            display: none;
            //@include media-min(1560px) {
            @include media-min($xl) {
                display: block;
            }
        }
        .recherche-par-categorie {
            display: none;
            @include media-min(1560px) {
                display: block;
                width: 30% !important;
                input {
                    border-radius: 4px!important;
                }
            }
        }
        .mastock-menu {
            width: 100%;
            .adtm_menu_container {
                #adtm_menu {
                    margin: 0;
                    border: none;
                    #adtm_menu_inner {
                        & > ul {
                            @include media-min($xl) {
                                display: flex;
                                justify-content: center;
                            }
                            a.adtm_toggle_menu_button span {
                                padding-top: 55px;
                            }
                            a.a-niveau1 {
                                float: none;
                            }
                            .header_logo_mobile {
                                float: left;
                                width: 9rem;
                                img {
                                    position: relative;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



/* ==================================================================================
**** HOME
 ================================================================================== */

/// POS SLIDESHOW Module ////////////////////////////////////////////
/////////////////////////////////////////////////////////////
.home-slider {
    display: none;
    @include media-min(680px) {
        display: block;
    }
    margin-bottom: 4rem;
    .desc1 {
        max-width:initial;
        width: 80%;
        top:50%;
        left: 50%;
        margin-left: 0;
        transform: translate(-50%,-50%);
        line-height: 1.8;
        //margin-top: 2rem;
        @include media-min($sm){
            margin-top: 1rem;
        }
        @include media-min(1400px){
            //margin-top: -65px;
        }
        h1 {
            color: #fff;
            font-size: 4vw;
            @include media-min($xl) {
                font-size: 50px;
            }
        }
        .title3{
            margin-bottom: 0;
            font-size: 3vw;
            @include media-min($xl) {
                font-size: 30px;
            }
        }
        .title4 {
            @include media-min($xl) {
                font-size: 18px;
            }
        }
        .readmore  {
            margin: 1rem 0 0;
            @include media-min($xl) {
                margin: 2.5rem 0 0;
            }
            a {
                &:hover {
                    background: $main-red;
                }
            }
        }
    }
    .nivo-controlNav {
        display: none;
    }
}


/// TOP BANNER Module ///////////////////////////////////////////////
/////////////////////////////////////////////////////////////
//<div class="wrapper">
//    <div class="box a">A</div>
//    <div class="box b">B</div>
//    <div class="box c">C</div>
//    <div class="box d">D</div>
//</div>
.wrapper, .htmlbanners1-inner {
    margin-top: 4rem;
    @include media-min(680px) {
        margin-top: 0;
    }
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5rem;
    //height: 76rem;
    @include media-min($md) {
        display: grid;
        grid-row-gap: 28px;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(100px, auto);
    }
    @include media-min($lg) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 28px;
        margin-left: 0;
        margin-right: 0;
    }

    .box {
        &.a, &.c, &.e, &.g, &.k, &.l {
            overflow: hidden;
            .calque {
                background: #dd010585;
                @include media-min($md) {
                    background: $main-red;
                }
                &:after {
                    background: $main-red;
                }
            }
        }
        &.b, &.d, &.f, &.h, &.i, &.j, &.m {
            overflow: hidden;
            .calque {
                background: #3333337a;
                @include media-min($md) {
                    background: #333;
                }
                &:after {
                    background: #333;
                }
            }
        }
        a.banner-link {
            @include media-min(400px) {
                width: 86vw;
            }
            @include media-min($md) {
                width: initial;
            }
            position: relative;
            display: block;
            margin-bottom: 2rem;
            img { width: 100%; }
            .calque {
                top: 0;
                height: 100%;
                left: 0;
                position: absolute;
                //right: 140px;
                right: 0;
                .banner-description {
                    position: absolute;
                    top: 1rem;
                    max-width: 10rem;
                    padding: 2rem 0 2rem 2rem;
                    color: white;
                    h2 {
                        color: white;
                        text-transform: uppercase;
                        font-size: 12px;
                        @include media-min($lg) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        @include media-min($md) {
            a.banner-link {
                margin-bottom: 0;
                .calque {
                    top: 0;
                    height: 100%;
                    left: 0;
                    right: 66%;
                    transition: all 0.5s ease-out;
                    &:hover {
                        right: 0;
                        &:after {
                            display: none;
                        }
                    }
                    &:after {
                        content: "";
                        transform: skew(-8deg);
                        height: 100%;
                        width: 4rem;
                        right: -2rem;
                        position: absolute;
                    }
                    .banner-description {
                        top: 1rem;
                        z-index: 10;
                        //padding: 1rem;
                    }
                }
                &:hover {
                    .calque  {
                        right: 0;
                        transition: all 0.5s ease-out;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            &.a {
                grid-column: 1;
                grid-row: 1;
                margin-right: 14px;
            }
            &.b {
                grid-column: 2;
                grid-row: 1;
                margin-left: 14px;
            }
            &.c {
                grid-column: 1;
                grid-row: 2;
                margin-right: 14px;
            }
            &.d {
                grid-column: 2;
                grid-row: 2;
                margin-left: 14px;
            }
            &.e {
                grid-column: 1;
                grid-row: 3;
                margin-right: 14px;
            }
            &.f {
                grid-column: 2;
                grid-row: 3;
                margin-left: 14px;
            }
            &.g {
                grid-column: 1;
                grid-row: 4;
                margin-right: 14px;
            }
            &.h {
                grid-column: 2;
                grid-row: 4;
                margin-left: 14px;
            }
            &.i {
                grid-column: 1;
                grid-row: 5/7;
                margin-right: 14px;
                a.banner-link {
                    height: 100%;
                    .calque {
                        top: 70% !important;
                        left: 0 !important;
                        right: 0 !important;
                        height: 30% !important;
                        &:after {
                            transform: skew(0, 8deg)!important;
                            height: 4rem!important;
                            width: 100% !important;
                            top: -2rem!important;
                            right: 0!important;
                        }
                        .banner-description {
                            //top: 1rem;
                            //padding: 1rem;
                        }
                    }
                    &:hover {
                        .calque {
                            top: 0 !important;
                            height: 100% !important;
                            transition: all 0.5s ease-out;
                        }
                    }
                }
            }
            &.j {
                grid-column: 2;
                grid-row: 4;
                margin-left: 14px;
            }
            &.k {
                grid-column: 1;
                grid-row: 4;
                margin-right: 14px;
            }
            &.l {
                grid-column: 2;
                grid-row: 5;
                margin-left: 14px;
            }
            &.m {
                grid-column: 1/4;
                grid-row: 7;
            }
        }
        @include media-min($lg) {
            margin: 0!important;
            &.a {
                grid-column: 1;
                grid-row: 1;
            }
            &.b {
                grid-column: 2;
                grid-row: 1;
            }
            &.c {
                grid-column: 3;
                grid-row: 1;
            }
            &.d {
                grid-column: 1;
                grid-row: 2;
            }
            &.e {
                grid-column: 2;
                grid-row: 2;
            }
            &.f {
                grid-column: 3;
                grid-row: 2;
            }
            &.g {
                grid-column: 1;
                grid-row: 3;
            }
            &.h {
                grid-column: 2;
                grid-row: 3;
            }
            &.i {
                grid-column: 3;
                grid-row: 3/5;
            }
            &.j {
                grid-column: 1;
                grid-row: 4;
            }
            &.k {
                grid-column: 2;
                grid-row: 4;
            }
            &.l {
                grid-column: 3;
                grid-row: 5;
            }
            &.m {
                grid-column: 1/3;
                grid-row: 5;
            }
        }
    }
}


/// POS Recommended Products Module /////////////////////////////////
/////////////////////////////////////////////////////////////
.produits-a-la-une {
    margin-bottom: 4.7rem;
    .pos_content {
        background: #393939;
        padding: 6rem 0 4rem;
        position: relative;
        overflow: hidden;
        & > div > img {
            position: absolute;
            top: 0;
        }
        .pos_title {
            margin-bottom: 2rem;
            border-bottom: 1px solid #666;
            h3 {
                text-transform: uppercase;
                color: #fff;
            }
        }
        .recommendedproductslide_position {
            width: 80%;
            margin: 0 auto;
            .item-product {
                display: flex;
                justify-content: space-between;
                article.js-product-miniature {
                    background: inherit;
                    width: 100%;
                    @include media-min($sm) {
                        width: 55%;
                        margin: 0 auto;
                    }
                    @include media-min($lg) {
                        width: 33%;
                        padding: 0 3rem;
                    }
                    .img_block {
                        padding: 0 8px;
                        & > a {
                            display: block;
                            height: 12rem;
                            position: relative;
                            //overflow: hidden;
                            img {
                                width: 100%;
                                height: 12rem;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                    .product_desc {
                        background: inherit;
                        min-height: 10rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        text-align: center;
                        padding: 20px 0;
                        margin-bottom: 2rem;
                        a { margin: 0; }
                        h3 a {
                            color: #fff!important;
                        }
                        .product-category p {
                            color: #666;
                        }
                        .btn-voir {
                            margin-top: 1rem;
                            a {
                                color: #fff;
                                padding: .6rem 2.6rem;
                                border: 1px solid white;
                                border-radius: 4px;
                                &:hover {
                                    background: red;
                                    border-color: red;
                                }
                            }
                        }
                        .hook-reviews {
                            margin-bottom: 0;
                        }
                    }
                    &:before {
                        background: inherit;
                    }
                }
                .js-product-miniature ,.thumb-category,.item_logo,.thumb-container{
                    img {
                        display:block;
                        width: 30%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}


/// POS New product Products Module /////////////////////////////////
/////////////////////////////////////////////////////////////
.produits-a-la-une.produits-nouveaux {
    margin-bottom: 4.7rem;
    .pos_content {
        & > div > img {
            filter: brightness(5.5);
        }
        background: #ffffff;
        .pos_title {
            border-bottom: 1px solid #666;
            h3 {
                text-transform: uppercase;
                color: #393939;
            }
        }
        .recommendedproductslide_position {
            .item-product {
                article.js-product-miniature {
                    background: inherit;
                    .product_desc {
                        background: inherit;
                        h3 a {
                            color: #393939!important;
                        }
                        .product-category p {
                            color: #666;
                        }
                        .btn-voir a {
                            color: #393939;
                            border: 1px solid #393939;
                            &:hover {
                                background: red;
                                border-color: red;
                            }
                        }
                    }
                    &:before {
                        background: inherit;
                    }
                }
            }
        }
    }
}


/// POS New product Products Module /////////////////////////////////
/////////////////////////////////////////////////////////////
.display-block-position-2 {
    .pos_new_product {
        .block-content {
            .owl-stage-outer {
                    .owl-stage {
                        width: inherit!important;
                        .owl-item {
                            width: inherit!important;
                            .item-product {
                                width: inherit;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                @include media-min($sm) {
                                    flex-direction: row;
                                }
                                @include media-min($lg) {
                                    flex-direction: column;
                                }
                                article {
                                    margin: 0;
                                    padding: 0;
                                    .img_block {
                                        float: none;
                                        margin: 0;
                                        width: 60%;
                                        @include media-min($sm) {
                                            width: 100%;
                                        }
                                        @include media-min($lg) {
                                            width: 60%;
                                            float: left;
                                        }
                                        @include media-min($lg) {
                                            width: 70%;
                                        }
                                    }
                                    .product_desc {
                                        text-align: center;
                                        float: none;
                                        @include media-min($lg) {
                                            float: left;
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                    }
            }
        }
    }
    .listedepots, .fb-page {
        display: none;
        @include media-min($lg) {
            display: block;
        }
    }
}
/// listedepots Module ///////////////////////////////////////////////
/////////////////////////////////////////////////////////////
.listedepots,
.suivez-nous {
    padding: 0 2rem;
    @include media-min($sm) {
        width: 540px;
        padding: 0;
        margin: 0 auto;
    }
    @include media-min($md) {
        width: 720px;
    }
    @include media-min($lg) {
        margin: initial;
        width: initial;
    }
    & > p { margin-bottom: 1rem }
    .pos_title {
        text-align: left;
    }
    .depots {
        .depot-1, .depot-2, .depot-3 {
            margin-bottom: .6rem;
            .depot-img {
                @include media-min($lg) {
                    width: 4rem;
                }
            }
            .depot-desc {
                margin-top: 6px;
                p { line-height: 1.2; }
                p:nth-child(1) {
                    color: $main-red;
                    font-weight: bold;
                    font-size: 14px;
                }
                p:nth-child(2) {
                    color: $main-black-2;
                    font-weight: bold;
                    font-size: 20px;
                    @include media-min($lg) {
                        font-size: 18px;
                    }
                    @include media-min($xl) {
                        font-size: 22px;
                    }
                }
                p:nth-child(3) {
                    color: $main-black-1;
                }
            }
        }
    }
}

/// mosaiqueproducts Module //////////////////////////////////////////
/////////////////////////////////////////////////////////////
.mosaique {
    @include media-min($xl) {
        padding-left: 3rem;
    }
    .mosaique_content {
        article.mosaique-produit {
            width: 100%;
            margin-top: 0;
            @include media-min($sm) { width: 48%; }
            @include media-min($xl) { width: 31%; }
            margin-bottom: 1rem;
            .img_block {
                & > a {
                    display: block !important;
                    height: 14rem !important;
                    position: relative !important;
                    //overflow: hidden !important;
                    img {
                        height: 14rem !important;
                        width: 70%;
                        @include media-min($sm) {
                            width: 100% !important;
                        }
                        position: absolute !important;
                        top: 50% !important;
                        left: 50% !important;
                        transform: translate(-50%, -50%) !important;
                    }
                }
            }
            .product_desc {
                text-align: center;
                padding: 20px 0;
                margin-top: -18px;
                min-height: 11rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .hook-reviews {
                    margin-bottom: 15px;
                    .star_content {
                        display: flex;
                        justify-content: center;
                    }
                }
                h4 {
                    margin: 0;
                }
                .price {
                    font-size: 20px;
                    font-weight: 700;
                    color: #ea3a3c;
                }
                .btn-voir {
                    margin-top: .8rem;
                    a {
                        color: $main-black-2;
                        padding: .6rem 2.6rem;
                        border: 1px solid $main-black-2;
                        border-radius: 4px;
                        &:hover {
                            background: red;
                            border-color: red;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

/// Bannière en bas de page (block statique en bas de page) //////////////////////////////////////////
/////////////////////////////////////////////////////////////
.banniere_du_bas {
    background: red;
    padding: 2rem;
    @include media-min($lg) {
        background: inherit;
        //background-image: url("http://mastock-dev.kap.heureuses.net/img/cms/center_ads copie.png");
        background-image: url("../../../../img/cms/center_ads copie.png");
        background-size: contain;
        background-repeat: no-repeat;
        padding-left: 2rem;
        padding-bottom: 7rem;
        padding-top: 3.4rem;
    }
    @include media-min($xl) {
        padding-top: 4.6rem;
    }
    & > div {
        @include media-min($lg) {
            margin-left: 7rem;
        }
        p {
            color: #fff;
            &:nth-child(1) {
                font-size: 18px;
                margin-bottom: 10px;
            }
            &:nth-child(2) {
                font-size: 20px;
                @include media-min($sm) {
                    font-size: 26px;
                }
                font-weight: bold;
                margin-bottom: 16px;
            }
            &:nth-child(3) {
                font-size: 16px;
                margin-bottom: 2rem;
            }
        }
        .btn-voir {
            a {
                color: #fff;
                padding: .4rem 2rem;
                border: 1px solid #333;
                border-radius: 4px;
                background: #333;
                font-weight: bold;
                &:hover {
                    background: #CF0509;
                }
            }
        }
    }
}

/// GMAP /////////////////////////////////////////////
////////////////////////////////////////////
.gmap-mastock-content {
    //height: 50vh;
    position: relative;
    //height: 34rem;
    #gmap-mastock {
        height: 100%;
    }
    a[href^="http://maps.google.com/maps"] {
        display: none !important;
    }
    a[href^="https://maps.google.com/maps"] {
        display: none !important;
    }
    div.gmnoprint[style="margin: 10px; z-index: 0; position: absolute; cursor: pointer; left: 0px; top: 0px;"],
    .gm-control-active.gm-fullscreen-control,
    .gmnoprint,
    .gmnoprint a,
    .gmnoprint span,
    .gm-style-cc,
    div.gm-svpc {
        display: none;
    }
    //&::after {
    //    content: "";
    //    position: absolute;
    //    background: #7c0000;
    //    top: 0;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
    //    opacity: .4;
    //}


    .mastock-nivillac-btn {
        position: absolute;
    }
    .mastock-derval-btn {
        position: absolute;
    }
    .mastock-montaigu-btn {
        position: absolute;
    }
}


/// FOOTER /////////////////////////////////////////////
////////////////////////////////////////////
.footer-container {
    p {
        color: #bbb;
        font-size: 14px;
        line-height: 2;
    }
    .logo-footer {
        img {
            width: 8rem;
            margin-bottom: 2rem;
        }
    }
    .footer_block {
        a {
            color: #FFF;
        }
    }
}


/* ==================================================================================
**** PAGES CATEGORY
 ================================================================================== */
#category {

    section#products-list {
        article {
            .product_desc {
                h3 {
                    text-transform: inherit;
                    a {
                        text-transform: inherit;
                    }
                }
            }
            .link-product-plus-dinfo > a {
                p {
                    font-weight: bold;
                    transition: all 200ms ease-in;
                    padding: .6rem;
                    background: #DD0105;
                    width: 9rem;
                    //margin: 0 auto;
                    color: #fff;
                    border-radius: 8px;
                    &:hover {
                        opacity: .6;
                    }
                }
            }
        }
    }
}

/* ==================================================================================
**** PAGES PRODUIT
 ================================================================================== */
#product {

    section#main {
        h1.namne_details {
            text-transform: inherit;
        }
        .product-prices {
            div.current-price {
                span {
                    text-transform: none;
                }
            }
        }
        .product-information {
            .product-actions {
                .product-additional-info {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 1rem;
                    .bouton-imprimer-page-produit {
                        margin-right: 1rem;
                        a {
                            font-size: 32px;
                            padding-top: 4px;
                        }
                    }
                    .social-sharing {
                        width: 41px;
                        //margin-right: 1rem;
                        padding-top: 0;
                        border-top: none;
                        margin-right: 1rem;
                    }
                    #send_friend_button {
                        font-size: 13px;
                        margin-right: auto;
                    }
                }
            }
        }
    }
    section.categoryproducts {

    }
}

/* ==================================================================================
**** PAGES CMS
 ================================================================================== */
#cms {
    .la-societe {
        p {
            line-height: 2;
        }
    }
}



/* ==================================================================================
**** BARRE DE RECHERCHE
 ================================================================================== */
.ui-corner-all.ui-widget-content {
    @include media-min(1560px) {
        width: 566px!important;
        top: 128px!important;
        left: inherit!important;
        right: 63px!important;
    }
    & > li.ui-menu-item {
        padding: 0 1rem;
        @include media-min($sm) {
            width: 80%;
            margin: 0 auto;
        }
        @include media-min(900px) {
            width: 60%;
        }
        @include media-min(1400px) {
            width: 40%;
        }
        @include media-min(1560px) {
            width: 100%;
        }
        &:hover {
            background: #e0e0e0;
        }
        & > a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                margin: 0 1rem;
                width: 7rem;
            }
            & > div {
                width: 12rem;
                @include media-min($sm) {
                    width: 18rem;
                }
                p {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }
}



/* ==================================================================================
**** FORMULAIRE DEVIS
 ================================================================================== */
#module-opartdevis-SimpleQuotation {

    h1, h2, h3, h4 {
        text-transform: inherit;
    }
    .h1, .h2 {
        color: #333333;
        text-align: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: none;
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: .8em;
        padding-bottom: .5em;
    }

    form#opartDevisForm {
        select[name="objet_devis"] {
            padding: .5rem 2.4rem;
        }
    }
}


/*
 * SLR : Etiquettes sur les produits
 */

.badge-container.wk_label_text_label {
    display: flex;
    margin-top: -14px;
    @include media-min(480px) {
        margin-top: -11px;
    }
    @include media-min(768px) {
        margin-top: -12px;
    }
    @include media-min(1200px) {
        margin-top: -13px;
    }
    .badge {
        height: 32px;
        padding: 0 8px 0 10px;
        margin: 0 -1px 0 0;
        font-family: 'Work Sans', sans-serif;
        font-size: 0.875rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        font-weight: 600;
        letter-spacing: -0.1px;
    }
    .badge__before svg {
        width: 10px;
        height: 10px;
        transform: scale(.8);
        transform-origin: right top;
    }
    .badge__after svg {
        width: 10px;
        height: 100%;
        transform: scale(.5);
        transform-origin: left top;
    }
}

.produits-a-la-une {
    .badge-container.wk_label_text_label {
        margin-top: -13px;
        @include media-min(1200px) {
            margin-top: -12px;
        }
    }
    &.produits-nouveaux {
        .badge-container.wk_label_text_label {
            margin-top: -12px;
            @include media-min(1200px) {
                margin-top: -11px;
            }
        }
    }
}

#product {
    .badge-container.wk_label_text_label {
        margin-top: -15px;
        @include media-min(768px) {
            margin-top: -13px;
        }
        @include media-min(1200px) {
            margin-top: -15px;
        }
    }
    #content {
        overflow: visible;
    }
}

.wk_label_position_2,
.wk_label_position_7 {
    left: 50%;
    transform: translateX(-49%);
}

.mosaique {
    .badge-container.wk_label_text_label {
        margin-top: -12px;
    }
    @include media-max($sm - 1) {
        .wk_label_position_1 {
            left: 16%;
        }
        .wk_label_position_3 {
            right: 16%;
        }
        .wk_label_position_4 {
            left: 16%;
        }
        .wk_label_position_5 {
            right: 16%;
        }
        .wk_label_position_6 {
            left: 16%;
        }
        .wk_label_position_8 {
            right: 16%;
        }
    }
}


.product-prices div.wk-product-banner {
    margin-top: .3rem;
    margin-bottom: .3rem;
    div {
        margin: 0;
    }
}


/*
 * SLR : ajout du logo Facebook
 */
header#header .header-nav .contact-nav {
    margin-top: 12px;
    a {
        padding: 0 4px;
        svg {
            position: relative;
            margin-bottom: -6px;
        }
    }
}


/*
 * SLR : fixer le menu en haut
 */

header#header {
    z-index: 100;
    position: fixed;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ddd;
}

.blockcart {
    @media (max-width:991px ) {
        float:right;
    }
}

main > #wrapper {
    margin-top: 170px;
    @include media-min(768px) {
        margin-top: 160px;
    }
    @include media-min(1200px) {
        margin-top: 240px;
    }
    @include media-min(1560px) {
        margin-top: 190px;
    }
}
.pos_bannerslide {
    margin-top: 144px;
    @include media-min(1200px) {
        margin-top: 228px;
    }
    @include media-min(1560px) {
        margin-top: 170px;
    }
}

#adtm_menu .li-niveau1 a.a-niveau1 .advtm_menu_span {
    font-weight: bold;
}

#wrapper .fb_iframe_widget_fluid span {
    text-align: center;
    @include media-min(992px) {
        text-align: justify;
    }
}
.fb_iframe_widget iframe {
    left: 20px;
    @include media-min(768px) {
        left: unset;
    }
}
