body {
  font-family: 'Work Sans', sans-serif;
}

h3 {
  font-size: 22px;
  text-transform: uppercase;
}

.row:after {
  display: none;
}

.pos_title {
  margin-bottom: 16px;
  padding-bottom: 8px;
  text-align: center;
}

@media (min-width: 768px) {
  .pos_title {
    text-align: left;
  }
}

/**
***************************************************************
** Suppresion provisoir des boutons de mise au paniers et autres..

.add-to-links, .product-price-and-shipping, .product-add-to-cart, .product-prices

.modal-content .product-actions,
.modal-content .product-prices
***************************************************************
 */
.modal-dialog .modal-content .modal-footer {
  display: flex;
}

.modal-dialog .modal-content .modal-footer .social-sharing {
  width: 12rem;
  padding-top: 0;
}

.modal-dialog .modal-content .modal-footer #send_friend_button {
  margin-left: auto;
}

header#header .header-nav {
  min-height: 43px;
  background: #000;
  border-bottom: none;
}

header#header .header-nav .header_logo {
  display: none;
}

@media (min-width: 1200px) {
  header#header .header-nav .header_logo {
    display: block;
  }
}

@media (min-width: 1560px) {
  header#header .header-nav .header_logo {
    display: none;
  }
}

header#header .header-nav .header_logo img {
  width: 7rem;
}

header#header .header-nav .contact-nav {
  margin-top: 14px;
  margin-left: auto;
  color: #fff;
}

header#header .header-nav .contact-nav a {
  color: #fff;
}

@media (min-width: 1560px) {
  header#header .header-nav .contact-nav {
    margin-left: auto;
  }
}

header#header .recherche-par-categorie {
  width: 100%;
}

@media (min-width: 1560px) {
  header#header .recherche-par-categorie {
    display: none;
  }
}

header#header .recherche-par-categorie input {
  height: 100% !important;
  padding-right: 0 !important;
  border-radius: inherit;
}

@media (min-width: 1200px) {
  header#header .header-top {
    padding: 1rem 4rem !important;
  }
}

header#header .header-top > div {
  padding: 0;
}

@media (min-width: 1200px) {
  header#header .header-top > div {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

header#header .header-top .header_logo_desktop {
  display: none;
}

@media (min-width: 1200px) {
  header#header .header-top .header_logo_desktop {
    display: block;
  }
}

header#header .header-top .recherche-par-categorie {
  display: none;
}

@media (min-width: 1560px) {
  header#header .header-top .recherche-par-categorie {
    display: block;
    width: 30% !important;
  }
  header#header .header-top .recherche-par-categorie input {
    border-radius: 4px !important;
  }
}

header#header .header-top .mastock-menu {
  width: 100%;
}

header#header .header-top .mastock-menu .adtm_menu_container #adtm_menu {
  margin: 0;
  border: none;
}

@media (min-width: 1200px) {
  header#header .header-top .mastock-menu .adtm_menu_container #adtm_menu #adtm_menu_inner > ul {
    display: flex;
    justify-content: center;
  }
}

header#header .header-top .mastock-menu .adtm_menu_container #adtm_menu #adtm_menu_inner > ul a.adtm_toggle_menu_button span {
  padding-top: 55px;
}

header#header .header-top .mastock-menu .adtm_menu_container #adtm_menu #adtm_menu_inner > ul a.a-niveau1 {
  float: none;
}

header#header .header-top .mastock-menu .adtm_menu_container #adtm_menu #adtm_menu_inner > ul .header_logo_mobile {
  float: left;
  width: 9rem;
}

header#header .header-top .mastock-menu .adtm_menu_container #adtm_menu #adtm_menu_inner > ul .header_logo_mobile img {
  position: relative;
  z-index: 1;
}

/* ==================================================================================
**** HOME
 ================================================================================== */
.home-slider {
  display: none;
  margin-bottom: 4rem;
}

@media (min-width: 680px) {
  .home-slider {
    display: block;
  }
}

.home-slider .desc1 {
  max-width: initial;
  width: 80%;
  top: 50%;
  left: 50%;
  margin-left: 0;
  transform: translate(-50%, -50%);
  line-height: 1.8;
}

@media (min-width: 576px) {
  .home-slider .desc1 {
    margin-top: 1rem;
  }
}

.home-slider .desc1 h1 {
  color: #fff;
  font-size: 4vw;
}

@media (min-width: 1200px) {
  .home-slider .desc1 h1 {
    font-size: 50px;
  }
}

.home-slider .desc1 .title3 {
  margin-bottom: 0;
  font-size: 3vw;
}

@media (min-width: 1200px) {
  .home-slider .desc1 .title3 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .home-slider .desc1 .title4 {
    font-size: 18px;
  }
}

.home-slider .desc1 .readmore {
  margin: 1rem 0 0;
}

@media (min-width: 1200px) {
  .home-slider .desc1 .readmore {
    margin: 2.5rem 0 0;
  }
}

.home-slider .desc1 .readmore a:hover {
  background: #DD0105;
}

.home-slider .nivo-controlNav {
  display: none;
}

.wrapper, .htmlbanners1-inner {
  margin-top: 4rem;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5rem;
}

@media (min-width: 680px) {
  .wrapper, .htmlbanners1-inner {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .wrapper, .htmlbanners1-inner {
    display: grid;
    grid-row-gap: 28px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
}

@media (min-width: 992px) {
  .wrapper, .htmlbanners1-inner {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 28px;
    margin-left: 0;
    margin-right: 0;
  }
}

.wrapper .box.a, .wrapper .box.c, .wrapper .box.e, .wrapper .box.g, .wrapper .box.k, .wrapper .box.l, .htmlbanners1-inner .box.a, .htmlbanners1-inner .box.c, .htmlbanners1-inner .box.e, .htmlbanners1-inner .box.g, .htmlbanners1-inner .box.k, .htmlbanners1-inner .box.l {
  overflow: hidden;
}

.wrapper .box.a .calque, .wrapper .box.c .calque, .wrapper .box.e .calque, .wrapper .box.g .calque, .wrapper .box.k .calque, .wrapper .box.l .calque, .htmlbanners1-inner .box.a .calque, .htmlbanners1-inner .box.c .calque, .htmlbanners1-inner .box.e .calque, .htmlbanners1-inner .box.g .calque, .htmlbanners1-inner .box.k .calque, .htmlbanners1-inner .box.l .calque {
  background: #dd010585;
}

@media (min-width: 768px) {
  .wrapper .box.a .calque, .wrapper .box.c .calque, .wrapper .box.e .calque, .wrapper .box.g .calque, .wrapper .box.k .calque, .wrapper .box.l .calque, .htmlbanners1-inner .box.a .calque, .htmlbanners1-inner .box.c .calque, .htmlbanners1-inner .box.e .calque, .htmlbanners1-inner .box.g .calque, .htmlbanners1-inner .box.k .calque, .htmlbanners1-inner .box.l .calque {
    background: #DD0105;
  }
}

.wrapper .box.a .calque:after, .wrapper .box.c .calque:after, .wrapper .box.e .calque:after, .wrapper .box.g .calque:after, .wrapper .box.k .calque:after, .wrapper .box.l .calque:after, .htmlbanners1-inner .box.a .calque:after, .htmlbanners1-inner .box.c .calque:after, .htmlbanners1-inner .box.e .calque:after, .htmlbanners1-inner .box.g .calque:after, .htmlbanners1-inner .box.k .calque:after, .htmlbanners1-inner .box.l .calque:after {
  background: #DD0105;
}

.wrapper .box.b, .wrapper .box.d, .wrapper .box.f, .wrapper .box.h, .wrapper .box.i, .wrapper .box.j, .wrapper .box.m, .htmlbanners1-inner .box.b, .htmlbanners1-inner .box.d, .htmlbanners1-inner .box.f, .htmlbanners1-inner .box.h, .htmlbanners1-inner .box.i, .htmlbanners1-inner .box.j, .htmlbanners1-inner .box.m {
  overflow: hidden;
}

.wrapper .box.b .calque, .wrapper .box.d .calque, .wrapper .box.f .calque, .wrapper .box.h .calque, .wrapper .box.i .calque, .wrapper .box.j .calque, .wrapper .box.m .calque, .htmlbanners1-inner .box.b .calque, .htmlbanners1-inner .box.d .calque, .htmlbanners1-inner .box.f .calque, .htmlbanners1-inner .box.h .calque, .htmlbanners1-inner .box.i .calque, .htmlbanners1-inner .box.j .calque, .htmlbanners1-inner .box.m .calque {
  background: #3333337a;
}

@media (min-width: 768px) {
  .wrapper .box.b .calque, .wrapper .box.d .calque, .wrapper .box.f .calque, .wrapper .box.h .calque, .wrapper .box.i .calque, .wrapper .box.j .calque, .wrapper .box.m .calque, .htmlbanners1-inner .box.b .calque, .htmlbanners1-inner .box.d .calque, .htmlbanners1-inner .box.f .calque, .htmlbanners1-inner .box.h .calque, .htmlbanners1-inner .box.i .calque, .htmlbanners1-inner .box.j .calque, .htmlbanners1-inner .box.m .calque {
    background: #333;
  }
}

.wrapper .box.b .calque:after, .wrapper .box.d .calque:after, .wrapper .box.f .calque:after, .wrapper .box.h .calque:after, .wrapper .box.i .calque:after, .wrapper .box.j .calque:after, .wrapper .box.m .calque:after, .htmlbanners1-inner .box.b .calque:after, .htmlbanners1-inner .box.d .calque:after, .htmlbanners1-inner .box.f .calque:after, .htmlbanners1-inner .box.h .calque:after, .htmlbanners1-inner .box.i .calque:after, .htmlbanners1-inner .box.j .calque:after, .htmlbanners1-inner .box.m .calque:after {
  background: #333;
}

.wrapper .box a.banner-link, .htmlbanners1-inner .box a.banner-link {
  position: relative;
  display: block;
  margin-bottom: 2rem;
}

@media (min-width: 400px) {
  .wrapper .box a.banner-link, .htmlbanners1-inner .box a.banner-link {
    width: 86vw;
  }
}

@media (min-width: 768px) {
  .wrapper .box a.banner-link, .htmlbanners1-inner .box a.banner-link {
    width: initial;
  }
}

.wrapper .box a.banner-link img, .htmlbanners1-inner .box a.banner-link img {
  width: 100%;
}

.wrapper .box a.banner-link .calque, .htmlbanners1-inner .box a.banner-link .calque {
  top: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
}

.wrapper .box a.banner-link .calque .banner-description, .htmlbanners1-inner .box a.banner-link .calque .banner-description {
  position: absolute;
  top: 1rem;
  max-width: 10rem;
  padding: 2rem 0 2rem 2rem;
  color: white;
}

.wrapper .box a.banner-link .calque .banner-description h2, .htmlbanners1-inner .box a.banner-link .calque .banner-description h2 {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
}

@media (min-width: 992px) {
  .wrapper .box a.banner-link .calque .banner-description h2, .htmlbanners1-inner .box a.banner-link .calque .banner-description h2 {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .wrapper .box a.banner-link, .htmlbanners1-inner .box a.banner-link {
    margin-bottom: 0;
  }
  .wrapper .box a.banner-link .calque, .htmlbanners1-inner .box a.banner-link .calque {
    top: 0;
    height: 100%;
    left: 0;
    right: 66%;
    transition: all 0.5s ease-out;
  }
  .wrapper .box a.banner-link .calque:hover, .htmlbanners1-inner .box a.banner-link .calque:hover {
    right: 0;
  }
  .wrapper .box a.banner-link .calque:hover:after, .htmlbanners1-inner .box a.banner-link .calque:hover:after {
    display: none;
  }
  .wrapper .box a.banner-link .calque:after, .htmlbanners1-inner .box a.banner-link .calque:after {
    content: "";
    transform: skew(-8deg);
    height: 100%;
    width: 4rem;
    right: -2rem;
    position: absolute;
  }
  .wrapper .box a.banner-link .calque .banner-description, .htmlbanners1-inner .box a.banner-link .calque .banner-description {
    top: 1rem;
    z-index: 10;
  }
  .wrapper .box a.banner-link:hover .calque, .htmlbanners1-inner .box a.banner-link:hover .calque {
    right: 0;
    transition: all 0.5s ease-out;
  }
  .wrapper .box a.banner-link:hover .calque:after, .htmlbanners1-inner .box a.banner-link:hover .calque:after {
    display: none;
  }
  .wrapper .box.a, .htmlbanners1-inner .box.a {
    grid-column: 1;
    grid-row: 1;
    margin-right: 14px;
  }
  .wrapper .box.b, .htmlbanners1-inner .box.b {
    grid-column: 2;
    grid-row: 1;
    margin-left: 14px;
  }
  .wrapper .box.c, .htmlbanners1-inner .box.c {
    grid-column: 1;
    grid-row: 2;
    margin-right: 14px;
  }
  .wrapper .box.d, .htmlbanners1-inner .box.d {
    grid-column: 2;
    grid-row: 2;
    margin-left: 14px;
  }
  .wrapper .box.e, .htmlbanners1-inner .box.e {
    grid-column: 1;
    grid-row: 3;
    margin-right: 14px;
  }
  .wrapper .box.f, .htmlbanners1-inner .box.f {
    grid-column: 2;
    grid-row: 3;
    margin-left: 14px;
  }
  .wrapper .box.g, .htmlbanners1-inner .box.g {
    grid-column: 1;
    grid-row: 4;
    margin-right: 14px;
  }
  .wrapper .box.h, .htmlbanners1-inner .box.h {
    grid-column: 2;
    grid-row: 4;
    margin-left: 14px;
  }
  .wrapper .box.i, .htmlbanners1-inner .box.i {
    grid-column: 1;
    grid-row: 5/7;
    margin-right: 14px;
  }
  .wrapper .box.i a.banner-link, .htmlbanners1-inner .box.i a.banner-link {
    height: 100%;
  }
  .wrapper .box.i a.banner-link .calque, .htmlbanners1-inner .box.i a.banner-link .calque {
    top: 70% !important;
    left: 0 !important;
    right: 0 !important;
    height: 30% !important;
  }
  .wrapper .box.i a.banner-link .calque:after, .htmlbanners1-inner .box.i a.banner-link .calque:after {
    transform: skew(0, 8deg) !important;
    height: 4rem !important;
    width: 100% !important;
    top: -2rem !important;
    right: 0 !important;
  }
  .wrapper .box.i a.banner-link:hover .calque, .htmlbanners1-inner .box.i a.banner-link:hover .calque {
    top: 0 !important;
    height: 100% !important;
    transition: all 0.5s ease-out;
  }
  .wrapper .box.j, .htmlbanners1-inner .box.j {
    grid-column: 2;
    grid-row: 4;
    margin-left: 14px;
  }
  .wrapper .box.k, .htmlbanners1-inner .box.k {
    grid-column: 1;
    grid-row: 4;
    margin-right: 14px;
  }
  .wrapper .box.l, .htmlbanners1-inner .box.l {
    grid-column: 2;
    grid-row: 5;
    margin-left: 14px;
  }
  .wrapper .box.m, .htmlbanners1-inner .box.m {
    grid-column: 1/4;
    grid-row: 7;
  }
}

@media (min-width: 992px) {
  .wrapper .box, .htmlbanners1-inner .box {
    margin: 0 !important;
  }
  .wrapper .box.a, .htmlbanners1-inner .box.a {
    grid-column: 1;
    grid-row: 1;
  }
  .wrapper .box.b, .htmlbanners1-inner .box.b {
    grid-column: 2;
    grid-row: 1;
  }
  .wrapper .box.c, .htmlbanners1-inner .box.c {
    grid-column: 3;
    grid-row: 1;
  }
  .wrapper .box.d, .htmlbanners1-inner .box.d {
    grid-column: 1;
    grid-row: 2;
  }
  .wrapper .box.e, .htmlbanners1-inner .box.e {
    grid-column: 2;
    grid-row: 2;
  }
  .wrapper .box.f, .htmlbanners1-inner .box.f {
    grid-column: 3;
    grid-row: 2;
  }
  .wrapper .box.g, .htmlbanners1-inner .box.g {
    grid-column: 1;
    grid-row: 3;
  }
  .wrapper .box.h, .htmlbanners1-inner .box.h {
    grid-column: 2;
    grid-row: 3;
  }
  .wrapper .box.i, .htmlbanners1-inner .box.i {
    grid-column: 3;
    grid-row: 3/5;
  }
  .wrapper .box.j, .htmlbanners1-inner .box.j {
    grid-column: 1;
    grid-row: 4;
  }
  .wrapper .box.k, .htmlbanners1-inner .box.k {
    grid-column: 2;
    grid-row: 4;
  }
  .wrapper .box.l, .htmlbanners1-inner .box.l {
    grid-column: 3;
    grid-row: 5;
  }
  .wrapper .box.m, .htmlbanners1-inner .box.m {
    grid-column: 1/3;
    grid-row: 5;
  }
}

.produits-a-la-une {
  margin-bottom: 4.7rem;
}

.produits-a-la-une .pos_content {
  background: #393939;
  padding: 6rem 0 4rem;
  position: relative;
  overflow: hidden;
}

.produits-a-la-une .pos_content > div > img {
  position: absolute;
  top: 0;
}

.produits-a-la-une .pos_content .pos_title {
  margin-bottom: 2rem;
  border-bottom: 1px solid #666;
}

.produits-a-la-une .pos_content .pos_title h3 {
  text-transform: uppercase;
  color: #fff;
}

.produits-a-la-une .pos_content .recommendedproductslide_position {
  width: 80%;
  margin: 0 auto;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product {
  display: flex;
  justify-content: space-between;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature {
  background: inherit;
  width: 100%;
}

@media (min-width: 576px) {
  .produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature {
    width: 55%;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature {
    width: 33%;
    padding: 0 3rem;
  }
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .img_block {
  padding: 0 8px;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .img_block > a {
  display: block;
  height: 12rem;
  position: relative;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .img_block > a img {
  width: 100%;
  height: 12rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc {
  background: inherit;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 2rem;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc a {
  margin: 0;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc h3 a {
  color: #fff !important;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .product-category p {
  color: #666;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .btn-voir {
  margin-top: 1rem;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .btn-voir a {
  color: #fff;
  padding: .6rem 2.6rem;
  border: 1px solid white;
  border-radius: 4px;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .btn-voir a:hover {
  background: red;
  border-color: red;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .hook-reviews {
  margin-bottom: 0;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product article.js-product-miniature:before {
  background: inherit;
}

.produits-a-la-une .pos_content .recommendedproductslide_position .item-product .js-product-miniature img, .produits-a-la-une .pos_content .recommendedproductslide_position .item-product .thumb-category img, .produits-a-la-une .pos_content .recommendedproductslide_position .item-product .item_logo img, .produits-a-la-une .pos_content .recommendedproductslide_position .item-product .thumb-container img {
  display: block;
  width: 30%;
  margin: 0 auto;
}

.produits-a-la-une.produits-nouveaux {
  margin-bottom: 4.7rem;
}

.produits-a-la-une.produits-nouveaux .pos_content {
  background: #ffffff;
}

.produits-a-la-une.produits-nouveaux .pos_content > div > img {
  filter: brightness(5.5);
}

.produits-a-la-une.produits-nouveaux .pos_content .pos_title {
  border-bottom: 1px solid #666;
}

.produits-a-la-une.produits-nouveaux .pos_content .pos_title h3 {
  text-transform: uppercase;
  color: #393939;
}

.produits-a-la-une.produits-nouveaux .pos_content .recommendedproductslide_position .item-product article.js-product-miniature {
  background: inherit;
}

.produits-a-la-une.produits-nouveaux .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc {
  background: inherit;
}

.produits-a-la-une.produits-nouveaux .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc h3 a {
  color: #393939 !important;
}

.produits-a-la-une.produits-nouveaux .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .product-category p {
  color: #666;
}

.produits-a-la-une.produits-nouveaux .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .btn-voir a {
  color: #393939;
  border: 1px solid #393939;
}

.produits-a-la-une.produits-nouveaux .pos_content .recommendedproductslide_position .item-product article.js-product-miniature .product_desc .btn-voir a:hover {
  background: red;
  border-color: red;
}

.produits-a-la-une.produits-nouveaux .pos_content .recommendedproductslide_position .item-product article.js-product-miniature:before {
  background: inherit;
}

.display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage {
  width: inherit !important;
}

.display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item {
  width: inherit !important;
}

.display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product {
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product {
    flex-direction: column;
  }
}

.display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product article {
  margin: 0;
  padding: 0;
}

.display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product article .img_block {
  float: none;
  margin: 0;
  width: 60%;
}

@media (min-width: 576px) {
  .display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product article .img_block {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product article .img_block {
    width: 60%;
    float: left;
  }
}

@media (min-width: 992px) {
  .display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product article .img_block {
    width: 70%;
  }
}

.display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product article .product_desc {
  text-align: center;
  float: none;
}

@media (min-width: 992px) {
  .display-block-position-2 .pos_new_product .block-content .owl-stage-outer .owl-stage .owl-item .item-product article .product_desc {
    float: left;
    text-align: left;
  }
}

.display-block-position-2 .listedepots, .display-block-position-2 .fb-page {
  display: none;
}

@media (min-width: 992px) {
  .display-block-position-2 .listedepots, .display-block-position-2 .fb-page {
    display: block;
  }
}

.listedepots,
.suivez-nous {
  padding: 0 2rem;
}

@media (min-width: 576px) {
  .listedepots,
  .suivez-nous {
    width: 540px;
    padding: 0;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .listedepots,
  .suivez-nous {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .listedepots,
  .suivez-nous {
    margin: initial;
    width: initial;
  }
}

.listedepots > p,
.suivez-nous > p {
  margin-bottom: 1rem;
}

.listedepots .pos_title,
.suivez-nous .pos_title {
  text-align: left;
}

.listedepots .depots .depot-1, .listedepots .depots .depot-2, .listedepots .depots .depot-3,
.suivez-nous .depots .depot-1,
.suivez-nous .depots .depot-2,
.suivez-nous .depots .depot-3 {
  margin-bottom: .6rem;
}

@media (min-width: 992px) {
  .listedepots .depots .depot-1 .depot-img, .listedepots .depots .depot-2 .depot-img, .listedepots .depots .depot-3 .depot-img,
  .suivez-nous .depots .depot-1 .depot-img,
  .suivez-nous .depots .depot-2 .depot-img,
  .suivez-nous .depots .depot-3 .depot-img {
    width: 4rem;
  }
}

.listedepots .depots .depot-1 .depot-desc, .listedepots .depots .depot-2 .depot-desc, .listedepots .depots .depot-3 .depot-desc,
.suivez-nous .depots .depot-1 .depot-desc,
.suivez-nous .depots .depot-2 .depot-desc,
.suivez-nous .depots .depot-3 .depot-desc {
  margin-top: 6px;
}

.listedepots .depots .depot-1 .depot-desc p, .listedepots .depots .depot-2 .depot-desc p, .listedepots .depots .depot-3 .depot-desc p,
.suivez-nous .depots .depot-1 .depot-desc p,
.suivez-nous .depots .depot-2 .depot-desc p,
.suivez-nous .depots .depot-3 .depot-desc p {
  line-height: 1.2;
}

.listedepots .depots .depot-1 .depot-desc p:nth-child(1), .listedepots .depots .depot-2 .depot-desc p:nth-child(1), .listedepots .depots .depot-3 .depot-desc p:nth-child(1),
.suivez-nous .depots .depot-1 .depot-desc p:nth-child(1),
.suivez-nous .depots .depot-2 .depot-desc p:nth-child(1),
.suivez-nous .depots .depot-3 .depot-desc p:nth-child(1) {
  color: #DD0105;
  font-weight: bold;
  font-size: 14px;
}

.listedepots .depots .depot-1 .depot-desc p:nth-child(2), .listedepots .depots .depot-2 .depot-desc p:nth-child(2), .listedepots .depots .depot-3 .depot-desc p:nth-child(2),
.suivez-nous .depots .depot-1 .depot-desc p:nth-child(2),
.suivez-nous .depots .depot-2 .depot-desc p:nth-child(2),
.suivez-nous .depots .depot-3 .depot-desc p:nth-child(2) {
  color: #000;
  font-weight: bold;
  font-size: 20px;
}

@media (min-width: 992px) {
  .listedepots .depots .depot-1 .depot-desc p:nth-child(2), .listedepots .depots .depot-2 .depot-desc p:nth-child(2), .listedepots .depots .depot-3 .depot-desc p:nth-child(2),
  .suivez-nous .depots .depot-1 .depot-desc p:nth-child(2),
  .suivez-nous .depots .depot-2 .depot-desc p:nth-child(2),
  .suivez-nous .depots .depot-3 .depot-desc p:nth-child(2) {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .listedepots .depots .depot-1 .depot-desc p:nth-child(2), .listedepots .depots .depot-2 .depot-desc p:nth-child(2), .listedepots .depots .depot-3 .depot-desc p:nth-child(2),
  .suivez-nous .depots .depot-1 .depot-desc p:nth-child(2),
  .suivez-nous .depots .depot-2 .depot-desc p:nth-child(2),
  .suivez-nous .depots .depot-3 .depot-desc p:nth-child(2) {
    font-size: 22px;
  }
}

.listedepots .depots .depot-1 .depot-desc p:nth-child(3), .listedepots .depots .depot-2 .depot-desc p:nth-child(3), .listedepots .depots .depot-3 .depot-desc p:nth-child(3),
.suivez-nous .depots .depot-1 .depot-desc p:nth-child(3),
.suivez-nous .depots .depot-2 .depot-desc p:nth-child(3),
.suivez-nous .depots .depot-3 .depot-desc p:nth-child(3) {
  color: #666666;
}

@media (min-width: 1200px) {
  .mosaique {
    padding-left: 3rem;
  }
}

.mosaique .mosaique_content article.mosaique-produit {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .mosaique .mosaique_content article.mosaique-produit {
    width: 48%;
  }
}

@media (min-width: 1200px) {
  .mosaique .mosaique_content article.mosaique-produit {
    width: 31%;
  }
}

.mosaique .mosaique_content article.mosaique-produit .img_block > a {
  display: block !important;
  height: 14rem !important;
  position: relative !important;
}

.mosaique .mosaique_content article.mosaique-produit .img_block > a img {
  height: 14rem !important;
  width: 70%;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

@media (min-width: 576px) {
  .mosaique .mosaique_content article.mosaique-produit .img_block > a img {
    width: 100% !important;
  }
}

.mosaique .mosaique_content article.mosaique-produit .product_desc {
  text-align: center;
  padding: 20px 0;
  margin-top: -18px;
  min-height: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mosaique .mosaique_content article.mosaique-produit .product_desc .hook-reviews {
  margin-bottom: 15px;
}

.mosaique .mosaique_content article.mosaique-produit .product_desc .hook-reviews .star_content {
  display: flex;
  justify-content: center;
}

.mosaique .mosaique_content article.mosaique-produit .product_desc h4 {
  margin: 0;
}

.mosaique .mosaique_content article.mosaique-produit .product_desc .price {
  font-size: 20px;
  font-weight: 700;
  color: #ea3a3c;
}

.mosaique .mosaique_content article.mosaique-produit .product_desc .btn-voir {
  margin-top: .8rem;
}

.mosaique .mosaique_content article.mosaique-produit .product_desc .btn-voir a {
  color: #000;
  padding: .6rem 2.6rem;
  border: 1px solid #000;
  border-radius: 4px;
}

.mosaique .mosaique_content article.mosaique-produit .product_desc .btn-voir a:hover {
  background: red;
  border-color: red;
  color: white;
}

.banniere_du_bas {
  background: red;
  padding: 2rem;
}

@media (min-width: 992px) {
  .banniere_du_bas {
    background: inherit;
    background-image: url("../../../../img/cms/center_ads copie.png");
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 2rem;
    padding-bottom: 7rem;
    padding-top: 3.4rem;
  }
}

@media (min-width: 1200px) {
  .banniere_du_bas {
    padding-top: 4.6rem;
  }
}

@media (min-width: 992px) {
  .banniere_du_bas > div {
    margin-left: 7rem;
  }
}

.banniere_du_bas > div p {
  color: #fff;
}

.banniere_du_bas > div p:nth-child(1) {
  font-size: 18px;
  margin-bottom: 10px;
}

.banniere_du_bas > div p:nth-child(2) {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

@media (min-width: 576px) {
  .banniere_du_bas > div p:nth-child(2) {
    font-size: 26px;
  }
}

.banniere_du_bas > div p:nth-child(3) {
  font-size: 16px;
  margin-bottom: 2rem;
}

.banniere_du_bas > div .btn-voir a {
  color: #fff;
  padding: .4rem 2rem;
  border: 1px solid #333;
  border-radius: 4px;
  background: #333;
  font-weight: bold;
}

.banniere_du_bas > div .btn-voir a:hover {
  background: #CF0509;
}

.gmap-mastock-content {
  position: relative;
}

.gmap-mastock-content #gmap-mastock {
  height: 100%;
}

.gmap-mastock-content a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

.gmap-mastock-content a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmap-mastock-content div.gmnoprint[style="margin: 10px; z-index: 0; position: absolute; cursor: pointer; left: 0px; top: 0px;"],
.gmap-mastock-content .gm-control-active.gm-fullscreen-control,
.gmap-mastock-content .gmnoprint,
.gmap-mastock-content .gmnoprint a,
.gmap-mastock-content .gmnoprint span,
.gmap-mastock-content .gm-style-cc,
.gmap-mastock-content div.gm-svpc {
  display: none;
}

.gmap-mastock-content .mastock-nivillac-btn {
  position: absolute;
}

.gmap-mastock-content .mastock-derval-btn {
  position: absolute;
}

.gmap-mastock-content .mastock-montaigu-btn {
  position: absolute;
}

.footer-container p {
  color: #bbb;
  font-size: 14px;
  line-height: 2;
}

.footer-container .logo-footer img {
  width: 8rem;
  margin-bottom: 2rem;
}

.footer-container .footer_block a {
  color: #FFF;
}

/* ==================================================================================
**** PAGES CATEGORY
 ================================================================================== */
#category section#products-list article .product_desc h3 {
  text-transform: inherit;
}

#category section#products-list article .product_desc h3 a {
  text-transform: inherit;
}

#category section#products-list article .link-product-plus-dinfo > a p {
  font-weight: bold;
  transition: all 200ms ease-in;
  padding: .6rem;
  background: #DD0105;
  width: 9rem;
  color: #fff;
  border-radius: 8px;
}

#category section#products-list article .link-product-plus-dinfo > a p:hover {
  opacity: .6;
}

/* ==================================================================================
**** PAGES PRODUIT
 ================================================================================== */
#product section#main h1.namne_details {
  text-transform: inherit;
}

#product section#main .product-prices div.current-price span {
  text-transform: none;
}

#product section#main .product-information .product-actions .product-additional-info {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

#product section#main .product-information .product-actions .product-additional-info .bouton-imprimer-page-produit {
  margin-right: 1rem;
}

#product section#main .product-information .product-actions .product-additional-info .bouton-imprimer-page-produit a {
  font-size: 32px;
  padding-top: 4px;
}

#product section#main .product-information .product-actions .product-additional-info .social-sharing {
  width: 41px;
  padding-top: 0;
  border-top: none;
  margin-right: 1rem;
}

#product section#main .product-information .product-actions .product-additional-info #send_friend_button {
  font-size: 13px;
  margin-right: auto;
}

/* ==================================================================================
**** PAGES CMS
 ================================================================================== */
#cms .la-societe p {
  line-height: 2;
}

/* ==================================================================================
**** BARRE DE RECHERCHE
 ================================================================================== */
@media (min-width: 1560px) {
  .ui-corner-all.ui-widget-content {
    width: 566px !important;
    top: 128px !important;
    left: inherit !important;
    right: 63px !important;
  }
}

.ui-corner-all.ui-widget-content > li.ui-menu-item {
  padding: 0 1rem;
}

@media (min-width: 576px) {
  .ui-corner-all.ui-widget-content > li.ui-menu-item {
    width: 80%;
    margin: 0 auto;
  }
}

@media (min-width: 900px) {
  .ui-corner-all.ui-widget-content > li.ui-menu-item {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  .ui-corner-all.ui-widget-content > li.ui-menu-item {
    width: 40%;
  }
}

@media (min-width: 1560px) {
  .ui-corner-all.ui-widget-content > li.ui-menu-item {
    width: 100%;
  }
}

.ui-corner-all.ui-widget-content > li.ui-menu-item:hover {
  background: #e0e0e0;
}

.ui-corner-all.ui-widget-content > li.ui-menu-item > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui-corner-all.ui-widget-content > li.ui-menu-item > a img {
  margin: 0 1rem;
  width: 7rem;
}

.ui-corner-all.ui-widget-content > li.ui-menu-item > a > div {
  width: 12rem;
}

@media (min-width: 576px) {
  .ui-corner-all.ui-widget-content > li.ui-menu-item > a > div {
    width: 18rem;
  }
}

.ui-corner-all.ui-widget-content > li.ui-menu-item > a > div p {
  font-size: 14px;
  font-weight: bold;
}

/* ==================================================================================
**** FORMULAIRE DEVIS
 ================================================================================== */
#module-opartdevis-SimpleQuotation h1, #module-opartdevis-SimpleQuotation h2, #module-opartdevis-SimpleQuotation h3, #module-opartdevis-SimpleQuotation h4 {
  text-transform: inherit;
}

#module-opartdevis-SimpleQuotation .h1, #module-opartdevis-SimpleQuotation .h2 {
  color: #333333;
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: none;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: .8em;
  padding-bottom: .5em;
}

#module-opartdevis-SimpleQuotation form#opartDevisForm select[name="objet_devis"] {
  padding: .5rem 2.4rem;
}

/*
 * SLR : Etiquettes sur les produits
 */
.badge-container.wk_label_text_label {
  display: flex;
  margin-top: -14px;
}

@media (min-width: 480px) {
  .badge-container.wk_label_text_label {
    margin-top: -11px;
  }
}

@media (min-width: 768px) {
  .badge-container.wk_label_text_label {
    margin-top: -12px;
  }
}

@media (min-width: 1200px) {
  .badge-container.wk_label_text_label {
    margin-top: -13px;
  }
}

.badge-container.wk_label_text_label .badge {
  height: 32px;
  padding: 0 8px 0 10px;
  margin: 0 -1px 0 0;
  font-family: 'Work Sans', sans-serif;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-weight: 600;
  letter-spacing: -0.1px;
}

.badge-container.wk_label_text_label .badge__before svg {
  width: 10px;
  height: 10px;
  transform: scale(0.8);
  transform-origin: right top;
}

.badge-container.wk_label_text_label .badge__after svg {
  width: 10px;
  height: 100%;
  transform: scale(0.5);
  transform-origin: left top;
}

.produits-a-la-une .badge-container.wk_label_text_label {
  margin-top: -13px;
}

@media (min-width: 1200px) {
  .produits-a-la-une .badge-container.wk_label_text_label {
    margin-top: -12px;
  }
}

.produits-a-la-une.produits-nouveaux .badge-container.wk_label_text_label {
  margin-top: -12px;
}

@media (min-width: 1200px) {
  .produits-a-la-une.produits-nouveaux .badge-container.wk_label_text_label {
    margin-top: -11px;
  }
}

#product .badge-container.wk_label_text_label {
  margin-top: -15px;
}

@media (min-width: 768px) {
  #product .badge-container.wk_label_text_label {
    margin-top: -13px;
  }
}

@media (min-width: 1200px) {
  #product .badge-container.wk_label_text_label {
    margin-top: -15px;
  }
}

#product #content {
  overflow: visible;
}

.wk_label_position_2,
.wk_label_position_7 {
  left: 50%;
  transform: translateX(-49%);
}

.mosaique .badge-container.wk_label_text_label {
  margin-top: -12px;
}

@media (max-width: 575px) {
  .mosaique .wk_label_position_1 {
    left: 16%;
  }
  .mosaique .wk_label_position_3 {
    right: 16%;
  }
  .mosaique .wk_label_position_4 {
    left: 16%;
  }
  .mosaique .wk_label_position_5 {
    right: 16%;
  }
  .mosaique .wk_label_position_6 {
    left: 16%;
  }
  .mosaique .wk_label_position_8 {
    right: 16%;
  }
}

.product-prices div.wk-product-banner {
  margin-top: .3rem;
  margin-bottom: .3rem;
}

.product-prices div.wk-product-banner div {
  margin: 0;
}

/*
 * SLR : ajout du logo Facebook
 */
header#header .header-nav .contact-nav {
  margin-top: 12px;
}

header#header .header-nav .contact-nav a {
  padding: 0 4px;
}

header#header .header-nav .contact-nav a svg {
  position: relative;
  margin-bottom: -6px;
}

/*
 * SLR : fixer le menu en haut
 */
header#header {
  z-index: 100;
  position: fixed;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 991px) {
  .blockcart {
    float: right;
  }
}

main > #wrapper {
  margin-top: 170px;
}

@media (min-width: 768px) {
  main > #wrapper {
    margin-top: 160px;
  }
}

@media (min-width: 1200px) {
  main > #wrapper {
    margin-top: 240px;
  }
}

@media (min-width: 1560px) {
  main > #wrapper {
    margin-top: 190px;
  }
}

.pos_bannerslide {
  margin-top: 144px;
}

@media (min-width: 1200px) {
  .pos_bannerslide {
    margin-top: 228px;
  }
}

@media (min-width: 1560px) {
  .pos_bannerslide {
    margin-top: 170px;
  }
}

#adtm_menu .li-niveau1 a.a-niveau1 .advtm_menu_span {
  font-weight: bold;
}

#wrapper .fb_iframe_widget_fluid span {
  text-align: center;
}

@media (min-width: 992px) {
  #wrapper .fb_iframe_widget_fluid span {
    text-align: justify;
  }
}

.fb_iframe_widget iframe {
  left: 20px;
}

@media (min-width: 768px) {
  .fb_iframe_widget iframe {
    left: unset;
  }
}
